jQuery(document).ready(function ($) {

  //Trigger burger menu open on mouseenter
  $('.av-burger-menu-main > a').on('mouseenter', function(){
    if(!$('html').hasClass('av-burger-overlay-active')){
      $(this).trigger('click');
    }
  });

  //Burger menu is dynamically created on the first trigger of the burger menu. We add the mouseenter handlers to links with submenus after its created
  $('.av-burger-menu-main > a').one('click', function(){
    let checkBurger = setInterval(function(){
      //check if burger menu creatd
      if($('.av-burger-overlay').length){
        clearInterval(checkBurger);

        //add handlers
        $('.av-width-submenu > a').on('mouseleave', function(){
          if($('html').hasClass('av-burger-overlay-active') && !$(this).closest('.av-width-submenu').hasClass('av-show-submenu')){
            $(this).trigger('click');
          }
        }); 

        $('.av-burger-overlay-scroll').on('mouseleave',  function(){
          if($('html').hasClass('av-burger-overlay-active')){
            $('.av-burger-menu-main > a').trigger('click'); 
          }
        }); 
      }
    }, 100); 
  });
  
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  /**
   * get the slug from the selected option
   * append and refresh the page or forward to the current page with url params
   */
  $("#selectShortcodeContent").on("change", function (event) {
    window.location =
      window.location.origin +
      window.location.pathname +
      "?selectcat=" +
      event.target.value;

      // var url = new URL(window.location);
      // url.searchParams.selectcat = event.target.value;
      // window.location = url.href;
  });



  /**
   * get the slug from the selected option
   * append and refresh the page or forward to the current page with url params
   */
  $("#shortcodeSearchBox").on("blur", function (event) {
    window.location =
      window.location.origin +
      window.location.pathname +
      "?search=" +
      event.target.value;

      // var url = new URL(window.location);
      // url.searchParams.search  = event.target.value;
      // window.location = url.href;
  });

  $("#shortcodeSearchBox").on('keypress', function(event){
    if( event.which == 13 ){
      window.location =
      window.location.origin +
      window.location.pathname +
      "?search=" +
      event.target.value;

      // var url = new URL(window.location);
      // url.searchParams.search = event.target.value;
      // window.location = url.href;

    }
  })

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
});
